/*======================================
===============for live use=============*/
export const environment = {
  production: false,
  apiUrl:'https://fd.fareboutique.com/',
  url : 'https://fd.fareboutique.com/',
  imageurl:'https://fd.fareboutique.com/uploads/userprofile/',
  airlineurl:'https://fd.fareboutique.com/uploads/airlines/',
  title: 'Supplier',
  // xkey: '1AKUO11640863661272',
  adminPath:'',
  firebase: {
    apiKey: "AIzaSyCYD9XULCFhZ85ITW2I_pH67ZxQG0Lh-6A",
    authDomain: "angularnoti-5224a.firebaseapp.com",
    projectId: "angularnoti-5224a",
    storageBucket: "angularnoti-5224a.appspot.com",
    messagingSenderId: "1015037219900",
    appId: "1:1015037219900:web:ed895e3899d80ec256df39",
    measurementId: "G-T1JKFBCXQ5",
    vapidKey: 'BB0DjFt-0d_n8Pg3aEPnGjn3NRAkFrEzAAP7tQl0xLHZUbo9joPkvXmazhQUcRDIrkdxK3DlHS988deNwevgg6Y'
  },
  /* if we build for client use main domain change adminPath "/admin"
   otherwise blank adminPath use for localhost //and online use other domain change according to your folder path*/
};

/*======================================
===============for dev/local use=============*/
// export const environment = {
//   production: false,
//   apiUrl: 'https://devapi.fareboutique.com/v1/',
//   url: 'https://devapi.fareboutique.com/',
//   imageurl: 'https://devapi.fareboutique.com/uploads/userprofile/',
//   airlineurl: 'https://devapi.fareboutique.com/uploads/airlines/',
//   adminPath: '',
//   title: 'Supplier',
//   // xkey: '1FMQKB1639407126571',
//   // adminPath:'',
//   key: '2PBP7IABZ2',
//   salt: 'DAH88E3UWQ',
//   firebase: {
//     apiKey: "AIzaSyCYD9XULCFhZ85ITW2I_pH67ZxQG0Lh-6A",
//     authDomain: "angularnoti-5224a.firebaseapp.com",
//     projectId: "angularnoti-5224a",
//     storageBucket: "angularnoti-5224a.appspot.com",
//     messagingSenderId: "1015037219900",
//     appId: "1:1015037219900:web:ed895e3899d80ec256df39",
//     measurementId: "G-T1JKFBCXQ5",
//     vapidKey: 'BB0DjFt-0d_n8Pg3aEPnGjn3NRAkFrEzAAP7tQl0xLHZUbo9joPkvXmazhQUcRDIrkdxK3DlHS988deNwevgg6Y'
//   },
//   //adminPath:'/fareboutique_demo',
//   /*if we build for client use main domain change adminPath "/admin" */
//   /* otherwise blank adminPath use for localhost //and online use other domain change according to your folder path*/
// };

/*======================================*/

